import axios, { AxiosResponse } from 'axios';

import { PUBLIC_CONTAINER_URL } from 'common/constants';
import { Subscription } from './types';

export const getSubscriptionsInfo = (): Promise<AxiosResponse<Subscription[]>> => {
  const subscriptionInfoURL =
    process.env.REACT_APP_SUBSCRIPTIONS_URL ||
    `${PUBLIC_CONTAINER_URL}/subscription_products/subscription_products.json`;

  return axios.get(subscriptionInfoURL);
};
