import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import fourPointLogo from 'assets/images/global/four-point-logo.svg';
import linkedinIcon from 'assets/images/footer/linkedin.svg';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { StyledFooterBottomContent } from 'components/Footer/FooterBottomContent/styled';
import { StyledFooter } from 'components/Footer/styled';
import { companiesData } from 'components/Footer/companyData';
import { FooterTopContent, FooterGrid, FooterAddress, FooterLinkedin, FooterNav, FooterNavList } from './styled';
import { footerNavigationListData } from './footerNavigationListData';

const DigitalTwinFooter = () => {
  const links = t('footer.navigation', { returnObjects: true }) as { id: number; content: string; }[];

  return (
    <StyledFooter pathname="">
      <PaddingWrapper size="small" type="inline">
        <Container size="wide" align="center">
          <FooterTopContent>
            <FooterGrid>
              <div style={{ gridArea: 'logo' }}>
                <img src={fourPointLogo} alt="Four Point" width={115} />
              </div>
              <div style={{ gridArea: 'address1' }}>
                <FooterAddress>
                  <address>
                    <Trans i18nKey={t('footer.addressPL')} />
                  </address>
                </FooterAddress>
              </div>
              <div style={{ gridArea: 'address2' }}>
                <FooterAddress>
                  <address>
                    <Trans i18nKey={t('footer.addressNL')} />
                  </address>
                  <FooterLinkedin>
                    <img src={linkedinIcon} alt="Linkedin" />
                    <a href={companiesData.linkedin.url} target="_blank" rel="noopener noreferrer">
                      {t('footer.linkedInLabel')}
                    </a>
                  </FooterLinkedin>
                </FooterAddress>
              </div>
              <FooterNav>
                <FooterNavList>
                  {links.map(({ id, content }) => (
                    <li key={id}>
                      <Link to={footerNavigationListData.filter((item) => item.id === id)[0].path}>{content}</Link>
                    </li>
                  ))}
                </FooterNavList>
              </FooterNav>
            </FooterGrid>
          </FooterTopContent>
          <StyledFooterBottomContent>
            <p>Remote Sensing Business Solutions &copy; {new Date().getFullYear()}</p>
          </StyledFooterBottomContent>
        </Container>
      </PaddingWrapper>
    </StyledFooter>
  );
};

export default DigitalTwinFooter;
