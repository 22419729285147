import { PUBLIC_CONTAINER_URL } from "common/constants";

const baseUrl = `${PUBLIC_CONTAINER_URL}/case_studies`;

export type CaseStudyFileType = 'case-study' | 'flyer' | 'booklet' | 'report';

export type CaseStudyFile = {
  titleHeading: string | null;
  title: string;
  userFileName: string;
  fileSrc: string;
  fileSize: number;
  fileType: CaseStudyFileType;
};

export type CaseStudy = {
  no: number;
  id: string;
  link: string;
  formTitle: string;
  homepage: {
    title: string;
    description: string;
    image: string;
  };
  subpage: {
    title: {
      main: string;
      additional: string | null;
    };
    description: string;
    image: {
      desktop: string;
      tablet: string;
    };
    file: CaseStudyFile;
    list: {
      id: number;
      content: string;
    }[];
  };
};

export const caseStudiesData: CaseStudy[] = [
  {
    no: 1,
    id: '1',
    link: 'spaceborne-mineral-mapping-hyperspectral-data',
    formTitle: 'Spaceborne Mineral Mapping Using EnMap Hyperspectral Data',
    homepage: {
      title: 'Spaceborne Mineral Mapping Using EnMap Hyperspectral Data',
      description:
        'This case study presents TerraEye\'s test results derived from a variety of different mapping methods and approaches using hyperspectral data from EnMAP mission.',
      image: require('assets/images/caseStudies/homepage/spaceborne-mineral.jpg'),
    },
    subpage: {
      title: {
        main: 'Spaceborne Mineral Mapping Using EnMap Hyperspectral Data',
        additional: null,
      },
      description: "",
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/spaceborne-mineral.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/spaceborne-mineral.jpg'),
      },
      file: {
        titleHeading: 'Spaceborne Mineral Mapping Using',
        title: 'EnMap Hyperspectral Data',
        userFileName: 'Spaceborne Mineral Mapping - Case Study',
        fileSrc: `${baseUrl}/Spaceborne Mineral Mapping - Case Study.pdf`,
        fileSize: 7546,
        fileType: 'case-study',
      },
      list: [
        {
          id: 1,
          content:
            'TerraEye\'s algorithms using a variety of different mapping methods and approaches compare to historical data.',
        },
        {
          id: 2,
          content:
            'A series of test results from surface mineral mapping methods using hyperspectral data from the EnMAP mission.',
        },
        {
          id: 3,
          content:
            "Methods used for mapping in this study: Spectral Angle Mapper (SAM), Mixture-Tuned Matched Filtering (MTMF), Geological Spectral Indices, Siamese Networks.",
        },
        {
          id: 4,
          content:
            "Assessment of effectiveness of Four Point's Automated MTMF Algorithm used in TerraEye.",
        },
      ],
    },
  },
  {
    no: 2,
    id: '2',
    link: 'terraeye-for-mineral-prospecting',
    formTitle: 'TerraEye for Mineral Prospecting',
    homepage: {
      title: 'TerraEye for Mineral Prospecting',
      description:
        'A quick look at the services provided by TerraEye in terms of Mineral Prospecting, summarizing the inner-working processes and ways in which we cooperate with our clients.',
      image: require('assets/images/caseStudies/homepage/mineral-prospecting.jpg')
    },
    subpage: {
      title: {
        main: 'TerraEye for Mineral Prospecting',
        additional: '— Short Flyer',
      },
      description: "",
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/mineral-prospecting.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/mineral-prospecting.jpg'),
      },
      file: {
        titleHeading: 'TerraEye for',
        title: 'Mineral Prospecting',
        userFileName: 'TerraEye For Mineral Prospecting - Flyer',
        fileSrc: `${baseUrl}/TerraEye For Mineral Prospecting - Flyer.pdf`,
        fileSize: 3842,
        fileType: 'flyer',
      },
      list: [
        {
          id: 1,
          content:
            'TerrEye supports mineral prospecting and exploration projects by providing mineral mapping services.',
        },
        {
          id: 2,
          content:
            'General informations about the TerraEye app and its inner workings.',
        },
        {
          id: 3,
          content:
            "How we collaborate and cooperate with mineral prospecting clients.",
        },
        {
          id: 4,
          content:
            "Summary of our approach to research and future development of TerraEye.",
        },
      ],
    },
  },
  {
    no: 3,
    id: '3',
    link: 'eye-over-mining-areas',
    formTitle: 'An Eye Over Mining Areas',
    homepage: {
      title: 'An Eye Over Mining Areas',
      description:
        'Examples of Geospatial Data Analyses for Better Environmental Monitoring, Decision-Making, ESG Reporting and Due Diligence for Mining collected into one booklet.',
      image: require('assets/images/caseStudies/homepage/mining-areas.jpg'),
    },
    subpage: {
      title: {
        main: 'An Eye Over Mining Areas',
        additional: null,
      },
      description: "",
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/mining-areas.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/mining-areas.jpg'),
      },
      file: {
        titleHeading: 'An Eye Over',
        title: 'Mining Areas',
        userFileName: 'Eye Over Mining Areas - Booklet',
        fileSrc: `${baseUrl}/Eye Over Mining Areas - Booklet.pdf`,
        fileSize: 13597,
        fileType: 'booklet',
      },
      list: [
        {
          id: 1,
          content:
            'TerraEye uses effective monitoring of longterm mining sites to provide important decision making insights that can be easily shared with your team.',
        },
        {
          id: 2,
          content:
            'To assess environmental impact on waterbodies, water quality, vegetation quality, land class changes, and disaster prevention with ground movement monitoring with the use of TerraEye.',
        },
        {
          id: 3,
          content:
            "ESG compliance can be supported by data extracted from within TerraEye creating an open and honest reporting, coupled with effective communication between stakeholders, including mining companies, regulatory bodies, and local communities.",
        },
      ],
    },
  },
  {
    no: 4,
    id: 'a6ea71d1-6c6b-42df-bb2c-199613b86926',
    link: 'satellite-driven-biodiversity-monitoring',
    formTitle: 'Satellite-Driven Biodiversity Monitoring',
    homepage: {
      title: 'Satellite-Driven Biodiversity Monitoring',
      description:
        'Leveraging advanced satellite data analytics, this study underscores the pivotal role of AI in driving tangible improvements in environmental conservation and biodiversity.',
      image: require('assets/images/caseStudies/homepage/satellite-driven.jpg'),
    },
    subpage: {
      title: {
        main: 'Satellite-Driven',
        additional: 'Biodiversity Monitoring',
      },
      description:
        "TerraEye offers a unique blend of AI-driven satellite data analytics tailored for the Mining, Oil & Gas industries. By providing precise, real-time insights into site conditions, environmental factors, and potential hazards, TerraEye not only ensures operational efficiency but also reduces costs associated with ground surveys, compliance fines, and operational risks. The platform's proactive monitoring and predictive capabilities can save significant time and money, making TerraEye an invaluable asset for businesses aiming for sustainable, efficient, and cost-effective operations in these sectors.",
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/satellite-driven.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/satellite-driven.jpg'),
      },
      file: {
        titleHeading: 'Possibilities of TerraEye:',
        title: 'Biodiversity Monitoring',
        userFileName: 'Biodiversity Monitoring - Case Study',
        fileSrc: `${baseUrl}/Biodiversity Monitoring - Case Study.pdf`,
        fileSize: 6879,
        fileType: 'case-study',
      },
      list: [
        {
          id: 1,
          content:
            'By monitoring biodiversity and vegetation health, TerraEye ensures that mining and drilling activities comply with environmental regulations, thus avoiding potential fines and legal complications.',
        },
        {
          id: 2,
          content:
            'Near Real-time satellite data can help in monitoring infrastructure, equipment movement, and site changes, leading to better logistical planning and operational efficiency in mining and drilling operations.',
        },
        {
          id: 3,
          content:
            "TerraEye's ability to detect changes in vegetation and ground displacement can act as an early warning system for potential landslides, water encroachments, or other environmental hazards, ensuring the safety of operations and infrastructure.",
        },
        {
          id: 4,
          content:
            "By showcasing a company's commitment to preserving biodiversity and adopting sustainable practices, TerraEye can enhance a company's reputation in the Mining, Oil & Gas industries, potentially leading to better stakeholder relations and investment opportunities.",
        },
      ],
    },
  },
  {
    no: 5,
    id: 'aa899022-c4ec-45aa-a91a-ced1cd4d49a1',
    link: 'ensuring-infrastructure-safety-through-satellite-monitoring',
    formTitle: 'Ensuring Infrastructure Safety through Satellite Monitoring in High-Risk Industries',
    homepage: {
      title: 'Ensuring Infrastructure Safety through Satellite Monitoring',
      description:
        "TerraEye's AI-driven satellite insights safeguard critical infrastructures, offering real-time monitoring around pipelines, roads, and rails in high-risk industries.",
      image: require('assets/images/caseStudies/homepage/ensuring-infrastructure.jpg'),
    },
    subpage: {
      title: {
        main: 'Ensuring Infrastructure Safety through Satellite Monitoring in High-Risk Industries',
        additional: null,
      },
      description:
        'For industries like Mining, Oil & Gas, and Pipeline Management, where the safety and integrity of infrastructures like pipelines, roads, and rails are paramount, TerraEye offers a unique solution. Its advanced satellite data analytics, combined with machine learning algorithms, provide real-time insights into potential threats caused by land deformations. By offering timely data and alerts, TerraEye can prevent costly damages, operational halts, and potential environmental hazards. This not only ensures operational safety but also results in significant cost and time savings, making TerraEye an indispensable tool for businesses in these sectors.',
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/ensuring-infrastructure.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/ensuring-infrastructure.jpg'),
      },
      file: {
        titleHeading: 'Possibilities of TerraEye:',
        title: 'Pipeline Monitoring',
        userFileName: 'Infrastructure Pipelines - Case Study',
        fileSrc: `${baseUrl}/Infrastructure Pipelines - Case Study.pdf`,
        fileSize: 4833,
        fileType: 'case-study',
      },
      list: [
        {
          id: 1,
          content:
            'TerraEye detects land subsidence caused by factors like underground mining or groundwater changes, crucial for infrastructure safety in areas with pipelines or rails.',
        },
        {
          id: 2,
          content:
            'Offering near real-time data on land subsidence, TerraEye aids industries in making timely decisions, especially in regions prone to infrastructure damage due to land deformations.',
        },
        {
          id: 3,
          content:
            'By detecting potential threats to pipelines, roads, and rails, TerraEye can prevent mechanical damages, leakages, and other failures, ensuring operational continuity.',
        },
        {
          id: 4,
          content:
            "TerraEye's upcoming notification system will alert users when land displacement velocities near infrastructures exceed permissible values, allowing for immediate interventions.",
        },
        {
          id: 5,
          content:
            "TerraEye's collaboration with experts from various fields, including satellite data providers and universities, ensures the highest quality of data and continuous improvement in its algorithms.",
        },
      ],
    },
  },
  {
    no: 6,
    id: '970e02f4-ef51-49ec-a6ab-e0686a7386b5',
    link: 'revolution-in-ghg-management-and-sustainability',
    formTitle: 'A Revolution in GHG Management and Sustainability',
    homepage: {
      title: 'A Revolution in GHG Management and Sustainability',
      description:
        "TerraEye's satellite analytics offers transformative insights into GHG emissions, enabling businesses to enhance sustainability and meet regulatory requirements.",
      image: require('assets/images/caseStudies/homepage/revolution-ghg.jpg'),
    },
    subpage: {
      title: {
        main: 'A Revolution in GHG Management and Sustainability',
        additional: null,
      },
      description:
        "For industries emphasizing GHG management and sustainability, TerraEye offers a game-changing solution. Its advanced satellite data analytics, combined with hyperspectral imagery, provide unparalleled insights into GHG emissions and removals. This not only ensures that businesses meet regulatory requirements but also positions them as leaders in sustainability. By offering timely and accurate data, TerraEye can prevent potential regulatory fines, reduce the costs associated with broad interventions, and enhance a company's reputation in the sustainability sector. Thus, TerraEye ensures both environmental stewardship and significant cost and time savings for businesses.",
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/revolution-ghg.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/revolution-ghg.jpg'),
      },
      file: {
        titleHeading: null,
        title: 'Greenhouse Gases Emissions and Removals',
        userFileName: 'Greenhouse Gases Emissions and Removals - Case Study',
        fileSrc: `${baseUrl}/Greenhouse Gases Emissions and Removals - Case Study.pdf`,
        fileSize: 5817,
        fileType: 'case-study',
      },
      list: [
        {
          id: 1,
          content:
            'TerraEye provides accurate localization and quantification of GHG emissions using hyperspectral imagery, enabling businesses to pinpoint emission sources.',
        },
        {
          id: 2,
          content:
            'By estimating above-ground biomass, which acts as a natural carbon sink, TerraEye aids businesses in reporting their GHG removal efforts, showcasing their commitment to offsetting emissions.',
        },
        {
          id: 3,
          content:
            "TerraEye's insights not only ensure regulatory compliance but also present an opportunity for businesses to drive positive environmental change and enhance their brand image.",
        },
        {
          id: 4,
          content:
            'TerraEye integrates with various data-gathering technologies, ensuring comprehensive insights into GHG emissions and removals.',
        },
        {
          id: 5,
          content:
            'With precise GHG monitoring and reporting, businesses can implement targeted reduction strategies, saving costs associated with broad-spectrum interventions and ensuring timely regulatory compliance.',
        },
      ],
    },
  },
  {
    no: 7,
    id: 'b39de77a-ae9d-40c5-a053-04d3f642bd5e',
    link: 'biodiversity-conservation-and-mining-impact-analysis',
    formTitle: "TerraEye's Pioneering Role in Biodiversity Conservation and Mining Impact Analysis",
    homepage: {
      title: 'Biodiversity Conservation and Mining Impact Analysis',
      description:
        "TerraEye's satellite analytics revolutionizes biodiversity conservation, offering insights into land use changes, water body dynamics, and mining impacts.",
      image: require('assets/images/caseStudies/homepage/biodiversity-conservation.jpg'),
    },
    subpage: {
      title: {
        main: "TerraEye's Pioneering Role in Biodiversity Conservation and Mining Impact Analysis",
        additional: null,
      },
      description:
        "For industries emphasizing biodiversity conservation, environmental management, and mining, TerraEye offers a transformative solution. Its advanced satellite data analytics provide real-time insights into land use changes, water body dynamics, and potential biodiversity risks. This not only ensures that businesses meet regulatory requirements but also positions them as leaders in sustainable operations. By offering timely and accurate data, TerraEye can prevent potential regulatory fines, reduce the costs associated with broad interventions, and enhance a company's reputation in the environmental sector. Thus, TerraEye ensures both environmental stewardship and significant cost and time savings for businesses.",
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/biodiversity-conservation.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/biodiversity-conservation.jpg'),
      },
      file: {
        titleHeading: null,
        title: 'Biodiversity Conservation and Mining Impact Analysis',
        userFileName: 'Sustainable Development Reporting With TerraEye - Case Study',
        fileSrc: `${baseUrl}/Sustainable Development Reporting With TerraEye - Case Study.pdf`,
        fileSize: 8885,
        fileType: 'case-study',
      },
      list: [
        {
          id: 1,
          content:
            'TerraEye provides invaluable data on land use changes, especially in mining areas, aiding industries in understanding and mitigating environmental impacts.',
        },
        {
          id: 2,
          content:
            'By analyzing changes in water surface areas, TerraEye helps industries assess the potential risks to aquatic ecosystems and species, ensuring sustainable operations.',
        },
        {
          id: 3,
          content:
            "TerraEye's insights into habitat areas and potential species extinction risks empower industries to adopt conservation measures and ensure regulatory compliance.",
        },
        {
          id: 4,
          content:
            'With precise environmental monitoring and reporting, businesses can implement targeted conservation strategies, saving costs associated with broad-spectrum interventions and ensuring timely regulatory compliance.',
        },
      ],
    },
  },
  {
    no: 8,
    id: '744515c7-795a-4a88-837d-825cb70c253b',
    link: 'infiltration-leakage-monitoring',
    formTitle: 'Infiltration Leakage Monitoring: A Deep Dive into Sierra Gorda SCM Tailing Pond Analysis',
    homepage: {
      title: 'Infiltration Leakage Monitoring',
      description:
        "TerraEye's satellite analytics unveils transformative insights into water infiltration issues, spotlighting the Sierra Gorda SCM tailing pond case.",
      image: require('assets/images/caseStudies/homepage/infiltration-leakage.jpg'),
    },
    subpage: {
      title: {
        main: 'Infiltration Leakage Monitoring:',
        additional: 'A Deep Dive into Sierra Gorda SCM Tailing Pond Analysis',
      },
      description:
        'For industries emphasizing water management, infrastructure maintenance, and mining, TerraEye offers a state-of-the-art solution. Its advanced satellite data analytics provide real-time insights into water infiltration issues, ensuring the safety and longevity of critical infrastructures like tailing ponds. Early detection and monitoring capabilities can lead to timely interventions, preventing costly damages and potential environmental hazards. This not only ensures operational safety but also results in significant cost and time savings, making TerraEye an indispensable tool for businesses in these sectors.',
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/infiltration-leakage.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/infiltration-leakage.jpg'),
      },
      file: {
        titleHeading: 'Possibilities of TerraEye:',
        title: 'Infiltration Leakage Monitoring',
        userFileName: 'Infiltration Leakage Monitoring - Case Study',
        fileSrc: `${baseUrl}/Infiltration Leakage Monitoring - Case Study.pdf`,
        fileSize: 4563,
        fileType: 'case-study',
      },
      list: [
        {
          id: 1,
          content:
            "TerraEye's solution provided a comprehensive historical analysis of water infiltration issues, tracing back from 2017 to 2023, offering a clear picture of the problem's evolution.",
        },
        {
          id: 2,
          content:
            'The platform detected specific problematic spots, ensuring targeted interventions and efficient resource allocation for mitigation.',
        },
        {
          id: 3,
          content:
            "Beyond tailing ponds, TerraEye's approach can be applied to detect leakages in pipelines and infiltration from underground water reservoirs, showcasing its versatility.",
        },
        {
          id: 4,
          content:
            'Early detection of water seepages and leakages can lead to timely interventions, preventing infrastructure damages and saving significant repair costs.',
        },
      ],
    },
  },
  {
    no: 9,
    id: '3a3fa23d-5ebe-4c7a-9a5b-3aecf47b0ef3',
    link: 'land-transformation-monitoring',
    formTitle: 'Land Transformation Monitoring: A Deep Dive into Industrial Site Analysis',
    homepage: {
      title: 'Land Transformation Monitoring',
      description:
        "Discover how TerraEye's AI-powered satellite data processing revolutionizes land transformation monitoring at an industrial site in Pune, India.",
      image: require('assets/images/caseStudies/homepage/land-transformation.jpg'),
    },
    subpage: {
      title: {
        main: 'Land Transformation Monitoring:',
        additional: 'A Deep Dive into Industrial Site Analysis',
      },
      description:
        "For industries focusing on industrial site management, environmental monitoring, and infrastructure development, TerraEye offers a comprehensive solution. Its advanced satellite data analytics, combined with drone and in-situ data collection, provide a holistic view of land transformations. This not only ensures that businesses can monitor and manage their sites effectively but also helps in early detection of potential issues, leading to timely interventions. By offering accurate and timely data, TerraEye can prevent potential operational challenges, reduce the costs associated with broad interventions, and enhance a company's efficiency in site management. Thus, TerraEye ensures both operational excellence and significant cost and time savings for businesses.",
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/land-transformation.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/land-transformation.jpg'),
      },
      file: {
        titleHeading: 'Possibilities of TerraEye:',
        title: 'Land Transformation Monitoring',
        userFileName: 'Land Transformation Monitoring - Case Study',
        fileSrc: `${baseUrl}/Land Transformation Monitoring - Case Study.pdf`,
        fileSize: 8522,
        fileType: 'case-study',
      },
      list: [
        {
          id: 1,
          content:
            'TerraEye provides multispectral images every 2-3 days, radar data every 12 days, and access to hyperspectral imagery for complex analyses.',
        },
        {
          id: 2,
          content:
            'For higher resolution needs, cloud-obscured locations, or satellite data validation, drones can be deployed to supplement the data.',
        },
        {
          id: 3,
          content:
            "TerraEye's approach is not limited to industrial sites; it can detect processes like waste accumulation, infrastructure development, and other land transformations.",
        },
      ],
    },
  },
  {
    no: 10,
    id: '3cf1a5f5-7c78-4e50-a267-c3ab552d425a',
    link: 'advanced-water-monitoring',
    formTitle: 'Advanced Water Monitoring: A Comprehensive Analysis of Reservoir Dynamics and Quality',
    homepage: {
      title: 'Advanced Water Monitoring',
      description:
        "Explore how TerraEye's satellite analytics revolutionizes water monitoring, from detecting surface area changes to assessing water quality in reservoirs.",
      image: require('assets/images/caseStudies/homepage/advanced-water-monitoring.jpg'),
    },
    subpage: {
      title: {
        main: 'Advanced Water Monitoring:',
        additional: 'A Comprehensive Analysis of Reservoir Dynamics and Quality',
      },
      description:
        "For industries focusing on water management, environmental monitoring, and reservoir management, TerraEye offers a comprehensive solution. Its advanced satellite data analytics provide real-time insights into water body dynamics, from surface area changes to water quality assessments. This not only ensures that businesses can monitor and manage their water resources effectively but also helps in early detection of potential issues, leading to timely interventions. By offering accurate and timely data, TerraEye can prevent potential environmental challenges, reduce the costs associated with broad interventions, and enhance a company's efficiency in water management. Thus, TerraEye ensures both environmental stewardship and significant cost and time savings for businesses.",
      image: {
        desktop: require('assets/images/caseStudies/subpage/desktop/advanced-water-monitoring.jpg'),
        tablet: require('assets/images/caseStudies/subpage/tablet/advanced-water-monitoring.jpg'),
      },
      file: {
        titleHeading: 'Possibilities of TerraEye:',
        title: 'Water Monitoring',
        userFileName: 'Water Monitoring - Case Study',
        fileSrc: `${baseUrl}/Water Monitoring - Case Study.pdf`,
        fileSize: 6389,
        fileType: 'case-study',
      },
      list: [
        {
          id: 1,
          content:
            'TerraEye offers enhanced water body visualization using high-resolution imagery, ensuring accurate surface area calculations and change monitoring.',
        },
        {
          id: 2,
          content:
            'With the Normalized Difference Chlorophyll Index, TerraEye provides insights into water quality, aiding in the detection of potential contamination or algal blooms.',
        },
        {
          id: 3,
          content:
            "TerraEye's analytics can identify human activities, like construction, that may impact reservoir surfaces, ensuring proactive management.",
        },
        {
          id: 4,
          content:
            'This feature allows for the identification of temperature variations in water bodies, which can be crucial for understanding ecological dynamics.',
        },
      ],
    },
  },
];

export const getCaseStudy = (caseStudyLink: string | undefined) => {
  return caseStudiesData.find((caseStudy) => caseStudy.link === caseStudyLink);
};
