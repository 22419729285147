export const theme = {
  colors: {
    white: 'hsla(0, 0%, 100%, 1)',
    lightWhite: 'hsla(0, 0%, 100%, 0.8)',
    gallery: 'hsla(0, 0%, 94%, 1)',
    alto: 'hsla(0, 0%, 88%, 1)',
    gray: 'hsla(0, 0%, 53%, 1)',
    copper: 'hsla(30, 59%, 45%, 1)',
    rawSienna: 'hsla(30, 56%, 53%, 1)',
    whiskey: 'hsla(32, 52%, 61%, 1)',
    bourbon: 'hsla(31, 78%, 43%, 1)',
    muleFawn: 'hsla(30, 52%, 38%, 1)',
    codGray: 'hsla(0, 4%, 11%, 1)',
    focusGray: 'hsla(27, 7%, 33%, 1)',
    black: 'hsla(0, 0%, 0%, 1)',
    tapaLight: '#7A7570',
    tuatara: 'hsla(30, 2%, 16%, 1)',
    chicago: 'hsla(30, 4%, 34%, 1)',
    thunder: 'hsla(320, 4%, 13%, 1)',
    masala: 'hsla(27, 8%, 23%, 1)',
    mineShaft: 'hsla(0, 4%, 13%, 1)',
    mineShaft_2: 'hsla(0, 3%, 17%, 1)',
    mineShaftLight: 'hsla(0, 0%, 18%, 1)',
    red: 'hsla(0, 77%, 53%, 1)',
    lightBrown: 'hsla(30, 16%, 57%, 1)',
    whiteTransparent10: 'hsla(0, 0%, 100%, 0.1)',
    whiteTransparent30: 'hsla(0, 0%, 100%, 0.3)',
    whiteTransparent50: 'hsla(0, 0%, 100%, 0.5)',
    rawSiennaTransparent15: 'hsla(30, 56%, 53%, 0.15)',
    scorpion: 'hsla(0, 0%, 36%, 1)',
    neptune: 'hsla(180, 37%, 60%, 1)',
    easternBlue: 'hsla(180, 65%, 36%, 1)',
  },
  breakpoints: {
    persistentSection: 1455,
    navigation: 1400,
    tabletLarge: 1320,
    tablet: 1100,
    tabletSmall: 980,
    mobile: 767,
    mobileSmall: 575,
    mobileTiny: 475,
  },
};

export const altTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    copper: 'hsla(180, 51%, 33%, 1)',
    rawSienna: 'hsla(180, 65%, 36%, 1)',
    whiskey: 'hsla(180, 53%, 36%, 1)',
    muleFawn: 'hsla(180, 53%, 36%, 1)',
  },
};
