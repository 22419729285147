import { Subscription } from './types';

const isSubscription = (data: any): data is Subscription => {
  return (
    data &&
    typeof data === 'object' &&
    (typeof data.area_quota === 'number' || data.area_quota === null) &&
    typeof data.info === 'string' &&
    typeof data.label === 'string' &&
    typeof data.name === 'string' &&
    typeof data.price === 'object' &&
    typeof data.price.current_amount === 'number' &&
    (typeof data.price.previous_amount === 'number' || data.price.previous_amount === null) &&
    typeof data.price.currency === 'string' &&
    typeof data.price.symbol === 'string'
  );
};

export const isSubscriptionsData = (data: any): data is Subscription[] => {
  return data !== undefined && Array.isArray(data) && data.every((item) => isSubscription(item));
};
