import { useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';

import {
  EcosystemItemDescription,
  EcosystemItemHeading,
  EcosystemItemVideo,
  EcosystemItemInfo,
  EcosystemItemSubheading,
  HeadingWrapper,
  StyledEcosystemItem,
} from './styled';
import { getVideoFormat } from 'utils/getVideoFormat';

type EcosystemItemProps = {
  title: string;
  subTitle: string;
  description: string;
  videoUrl: string;
  digitalTwin?: boolean;
};

const EcosystemItem = ({ title, subTitle, description, videoUrl, digitalTwin }: EcosystemItemProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoElement?.play();
        } else {
          videoElement?.pause();
        }
      },
      {
        threshold: 1,
      },
    );

    if (videoElement) {
      observer.observe(videoElement);

      return () => {
        observer.unobserve(videoElement);
      };
    }
  }, []);

  return (
    <StyledEcosystemItem digitalTwin={digitalTwin}>
      <EcosystemItemInfo>
        <HeadingWrapper>
          <EcosystemItemHeading>{title}</EcosystemItemHeading>
          <EcosystemItemSubheading>{subTitle}</EcosystemItemSubheading>
        </HeadingWrapper>
        <EcosystemItemDescription as='div' variant={digitalTwin ? 'SECONDARY' : 'PRIMARY'}>
          <Trans i18nKey={description} components={({ 1: <ul />, 2: <li /> })} />
        </EcosystemItemDescription>
      </EcosystemItemInfo>
      <EcosystemItemVideo ref={videoRef} muted loop playsInline>
        <source src={`${videoUrl}.${getVideoFormat()}`} type={`video/${getVideoFormat()}`} />
      </EcosystemItemVideo>
    </StyledEcosystemItem>
  );
};

export default EcosystemItem;
