import styled from 'styled-components';

import { TERRAEYE_APP_SIGN_UP_URL } from 'common/constants';
import { BaseButton } from 'ui/atoms/button/Button';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import { Section } from 'components/Section';
import { Subscriptions } from './Subscriptions';

export const MineralProspectingPricing = ({ id }: { id: string }) => {
  return (
    <Section id={id} large>
      <SectionHeader
        variant="SECONDARY_H2"
        title="Mineral Prospecting Solution"
        description={
          <>
            Want to test our mineral prospecting application?
            <br /> Try it by subscription and test the possibilities of TerraEye!
          </>
        }
        isBackgroundLight
      />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <SubscriptionsDescriptionWrapper>
            <p>
              With data available in minutes, subscription is the best economical option for general insights, and for
              initial understanding of the area of interest. It is easily accessible from inside the TerraEye app.
              <br />
              <br />
              Don't have a TerraEye login?
            </p>
            <BaseButton as="a" href={TERRAEYE_APP_SIGN_UP_URL} target="_blank" rel="noopener noreferrer" size="SM">
              sign up
            </BaseButton>
          </SubscriptionsDescriptionWrapper>
          <Subscriptions />
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

const SubscriptionsDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 700px;
  color: ${({ theme }) => theme.colors.tuatara};
  margin: 60px 0 80px;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 16px;
  }

  a {
    width: 210px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-column: span 2;

    p {
      font-size: 16px;
    }

    a {
      width: 140px;
      font-size: 10px;
      line-height: 36px;
      letter-spacing: 0.1em;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-column: 1;

    p {
      font-size: 14px;
    }
  }
`;
