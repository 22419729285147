export const dataSourceTableHeaders = [
  {
    id: 1,
    header: 'EO data source',
  },
  {
    id: 2,
    header: 'Wavelength [nm]',
  },
  {
    id: 3,
    header: 'Include TIR',
  },
  {
    id: 4,
    header: 'No. of bands',
  },
  {
    id: 5,
    header: 'Spatial res. [m]',
  },
];

export const dataSourceTableRows = [
  {
    id: 1,
    cells: ['World View 3', '400 - 2365', 'No', '16', '1.24 - 4.10'],
  },
  {
    id: 2,
    cells: ['Sentinel-2', '443 - 2202', 'No', '12', '10 - 60'],
  },
  {
    id: 3,
    cells: ['Landsat 8', '430 - 12510', 'Yes', '11', '30 - 100'],
  },
  {
    id: 4,
    cells: ['Aster', '520 - 11650', 'Yes', '14', '15 - 90'],
  },
  {
    id: 5,
    cells: ['Prisma', '400 - 2500', 'No', '250', '30'],
  },
  {
    id: 6,
    cells: ['EnMap', '420 - 2450', 'No', '230', '30'],
  },
  {
    id: 7,
    cells: ['Hyperion', '357 - 2576', 'No', '220', '30'],
  },
  {
    id: 8,
    cells: ['EMIT', '381 - 2493', 'No', '285', '60'],
  },
  {
    id: 9,
    cells: ['Constellr', '8000 - 14000', 'Yes', '—', '10'],
  },
  {
    id: 10,
    cells: ['Pixxel', '—', '—', '—', '—'],
  },
  {
    id: 11,
    cells: ['ICEye SAR', '3000 - 10000', 'Yes', '—', '0.25 - 3'],
  },
  {
    id: 12,
    cells: ['Airbus Pleiades NEO', '450 - 900', 'No', '4', '0.3'],
  },
  {
    id: 13,
    cells: ['Satellogic', '400 - 900', 'No', '4', '0.7'],
  },
  {
    id: 14,
    cells: ['PlanetScope', '450 - 880', 'No', '4', '3'],
  },
  {
    id: 15,
    cells: ['Planet SkySat', '450 - 900', 'No', '5', '0.72'],
  },
];
